<template>
    <div class="columns">
        <div class="content" v-if="contentStatus">
            <Form v-model="formData" ref="form" />

            <a href="#" :title="text.save" class="btn btn-primary" @click="save($event)" @keyup.enter="save($event)">{{text.save}}</a> <router-link :to="{ name: 'AdminMediaGroupMediaList' }" :title="text.cancel" class="btn btn-tertiary">{{text.cancel}}</router-link>
        </div>
    </div>
</template>

<script>
    import {log} from "@/shared/utils";
    import lang from "@/shared/lang";
    import {formWrapperMixin} from "@/views/mixins/formwrapper";
    import {addMediaGroup} from "@/shared/dataservice";

    import Form from "@/components/form/form";

    export default {
        name: 'MediaGroupNew',
        components: {Form},
        mixins: [formWrapperMixin],
        data() {
            return {
                mediaGroupData: {},
                contentStatus: true,
                formData: [
                    {
                        id: 1,
                        type: 'text',
                        name: 'mediagroup_name',
                        label: 'Library Name',
                        placeholder: "",
                        tooltip: "",
                        value : "",
                        validate : {
                            type: 'string',
                            required: true,
                            min: 3,
                            max: 127
                        }
                    },
                ]

            }
        },
        computed: {
            text() {
                return lang
            }
        },
        created() {
        },
        methods: {
            async save(e){
                e.preventDefault();

                log(["Save triggered by wrapper"]);

                if(this.$refs.form.save()){

                    // save updated value into helpData
                    this.mediaGroupData.name = this.getFormDataValueByName(this.formData, "mediagroup_name");

                    log(["Form data", this.mediaGroupData.name]);

                    let result = await addMediaGroup(this.mediaGroupData);

                    if(result.status === 201){
                        this.$router.push( { name: 'AdminMediaGroupMediaList', params: { message: 'added'} } );
                    }
                    else
                    {
                        log(["Form save result no ok"]);
                    }
                }
                else
                {
                    // TO DO SHOW ERROR
                    log(["Form is NOT ok to be saved"]);
                }
            }
        }
    }
</script>